.ie-custom-properties {
  blue: #20a8d8;
  indigo: #6610f2;
  purple: #6f42c1;
  pink: #e83e8c;
  red: #f86c6b;
  orange: #f8cb00;
  yellow: #ffc107;
  green: #4dbd74;
  teal: #20c997;
  cyan: #17a2b8;
  white: #fff;
  gray: #73818f;
  gray-dark: #2f353a;
  light-blue: #63c2de;
  gray-100: #f0f3f5;
  gray-200: #e4e7ea;
  gray-300: #c8ced3;
  gray-400: #acb4bc;
  gray-500: #8f9ba6;
  gray-600: #73818f;
  gray-700: #5c6873;
  gray-800: #2f353a; // NOTE: IN-USE
  gray-900: #23282c;
  primary: #20a8d8;
  secondary: #c8ced3;
  success: #4dbd74;
  info: #63c2de;
  warning: #ffc107;
  danger: #f86c6b;
  light: #f0f3f5;
  dark: #2f353a;
  breakpoint-xs: 0;
  breakpoint-sm: 576px;
  breakpoint-md: 768px;
  breakpoint-lg: 992px;
  breakpoint-xl: 1200px; }

// .modal-open {
//   overflow: hidden;
// }
// .modal {
//   position: fixed;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   z-index: 1050;
//   display: none;
//   overflow: hidden;
//   outline: 0;
// }
// .modal-open .modal {
//   overflow-x: hidden;
//   overflow-y: auto;
// }
// .modal-dialog {
//   position: relative;
//   width: auto;
//   margin: 0.5rem;
//   pointer-events: none;
// }
// .modal.fade .modal-dialog {
//   transition: transform 0.3s ease-out;
//   transform: translate(0, -25%);
// }
// @media screen and (prefers-reduced-motion: reduce) {
//   .modal.fade .modal-dialog {
//     transition: none;
//   }
// }
// .modal.show .modal-dialog {
//   transform: translate(0, 0);
// }
// .modal-dialog-centered {
//   margin: auto;
//   width: 50%;
//   display: flex;
//   align-items: center;
//   min-height: calc(100% - (0.5rem * 2));
// }
// .modal-content {
//   position: relative;
//   display: flex;
//   flex-direction: column;
//   width: 100%;
//   pointer-events: auto;
//   background-color: #fff;
//   background-clip: padding-box;
//   border: 1px solid rgba(0, 0, 0, 0.2);
//   border-radius: 0.3rem;
//   outline: 0;
// }
// .modal-backdrop {
//   position: fixed;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   z-index: 1040;
//   background-color: #000;
// }
// .modal-backdrop.fade {
//   opacity: 0;
// }
// .modal-backdrop.show {
//   opacity: 0.5;
// }
// .modal-header {
//   display: flex;
//   align-items: flex-start;
//   justify-content: space-between;
//   padding: 1rem;
//   border-bottom: 1px solid #e4e7ea;
//   border-top-left-radius: 0.3rem;
//   border-top-right-radius: 0.3rem;
//   width: 100%;
//   // border: #4dbd74 1px solid;
// }
// .modal-header .close {
//   padding: 1rem;
//   margin: -1rem -1rem -1rem auto;
// }
// .modal-title {
//   margin-bottom: 0;
//   line-height: 1.5;
//   width: 100%;
// }
// .modal-body {
//   position: relative;
//   flex: 1 1 auto;
//   padding: 1rem;
//   // overflow: auto;
//   // height: 20rem;
// }
// .modal-footer {
//   display: flex;
//   align-items: center;
//   justify-content: flex-end;
//   padding: 1rem;
//   border-top: 1px solid #e4e7ea;
// }
// .modal-footer > :not(:first-child) {
//   margin-left: 0.25rem;
// }
// .modal-footer > :not(:last-child) {
//   margin-right: 0.25rem;
// }
// .modal-scrollbar-measure {
//   position: absolute;
//   top: -9999px;
//   width: 50px;
//   height: 50px;
//   overflow: scroll;
// }

// .header-container {
//   display: table;
//   justify-content: space-between;
//   align-items: center;
//   padding: 0 1rem;
//   height: 9.7rem;
//   width: 100%;
//   padding-top: 1rem;
//   background-color: aliceblue;
//   border-bottom: 1px solid #e4e7ea;
//   border-top-left-radius: 1rem;
//   border-top-right-radius: 1rem;
//   border: 1px inset #e4e7ea;
// }

// .detail-container {
//   display: inline-block; 
//   *display: inline; 
//   zoom: 1; 
//   vertical-align: top; 
//   font-size: 12px;
//   overflow: auto;
//   overflow-x: hidden;
//   background-color: white;
//   height: 50rem;
//   width: 100%;
//   border-style: ridge;
//   border-bottom-left-radius: 1rem;
//   border-bottom-right-radius: 1rem;
// }