
.modal-open {
  overflow: hidden;
}
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}
.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -25%);
}
@media screen and (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: translate(0, 0);
}
.modal-dialog-centered {
  margin: auto;
  width: 50%;
  display: flex;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2));
}
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}
.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}
.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e4e7ea;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  width: 100%;
  // border: #4dbd74 1px solid;
}
.modal-header .close {
  padding: 1rem;
  margin: -1rem -1rem -1rem auto;
}
.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
  width: 100%;
}
.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
  // overflow: auto;
  // height: 20rem;
}
.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e4e7ea;
}
.modal-footer > :not(:first-child) {
  margin-left: 0.25rem;
}
.modal-footer > :not(:last-child) {
  margin-right: 0.25rem;
}
.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

/* Tab Container */
.Tabs {
  width: 80%;
  height: auto;
  min-height: 400px;
  background: white;
  padding: 2rem 1rem;
  color: #e8f0f2;
  border-radius: 1rem;
}

.react-tabs {
  -webkit-tap-highlight-color: transparent;
  // position: relative; /* Added if overlay is needed */
}

.react-tabs__tab-list {
  width: 90%;
  margin: 0 auto 0.5rem;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  border: 1px solid #dcdcdc;
  border-radius: 1rem;
  padding-left: 0pxz;
  margin-top: 0.75rem;
  font-size: medium;
  background-color: white;
  height: 2.5rem;
  align-content: center;
}

.react-tabs__tab,
.react-tabs__tab--selected {
  width: 100%;
  padding: 0.8rem;
  // list-style: none;
  text-align: center;
  cursor: pointer;
  transition: all 0.7s;
  // font-size: medium;
  // height: 2.85rem;
}

.react-tabs__tab--selected {
  background: #308efe;
  color: #ffff;
  // font-size: 1rem;
  // font-weight: 800;
  // font-size: large;
  // height: 2.5rem;
}

.react-tabs__tab--disabled {
  color: GrayText;
  cursor: default;
}

.react-tabs__tab:hover {
  background: #f0f8ff;
  border-radius: 1rem;
}

.react-tabs__tab:first-child {
  border-bottom-left-radius: 1rem;
  border-top-left-radius: 1rem;
}

.react-tabs__tab:last-child {
  border-bottom-right-radius: 1rem;
  border-top-right-radius: 1rem;
}

.react-tabs__tab-panel {
  display: none;
}

.react-tabs__tab-panel--selected {
  display: block;
  font-size: 1.25rem;
  text-align: center;
}

.draft-player-info-card__title {
  display: flex;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  color: #333;
  display: flex; /* Add flex container */
  align-items: flex-start; /* Center items vertically */

  .draft-player-info-card__image {
    width: 75px; /* Adjust the width as needed */
    height: 75px; /* Adjust the height as needed */
    object-fit: cover;
    margin-right: 0.1rem; /* Add margin for spacing */
  }

  .draft-player-info-card__player-info {
    flex: 1; /* Take up remaining space */
  }

  .draft-player-info-card__name {
    display: flex; /* Use flex to align items inline */
    align-items: center; /* Center items vertically */

    margin-top: 1rem;
    font-size: larger;
  }

  .draft-player-info-card__team {
    font-size: medium;
  }

  .draft-player-info-card__player-position {
    margin-left: 0.5rem; /* Add some space between name and position */
    font-size: large;
    font-weight: 800;
  }

  .draft-player-info-card__player-ranking-container {
    width: 40%;
    display: flex;
    justify-content: space-between;
    margin: 1rem 0;
    margin-right: 2rem;
    padding: 0 1rem;
  }

  .draft-player-info-card__player-ranking {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    font-weight: 800;
    border: #dcdcdc 1px solid;
    border-radius: 0.5rem;
  }

  .draft-player-info-card__detail_container {
    display: inline-block;
    *display: inline;
    zoom: 1;
    vertical-align: top;
    font-size: 12px;
    overflow: auto;
    overflow-x: hidden;
    background-color: white;
    height: 50rem;
    width: 100%;
    border-style: ridge;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
}

.playerDepthCharItem {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #e0e0e0;
  border-radius: 0.5rem;
  background-color: #fff;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease-in-out;
  margin-bottom: 0.5rem;

  &:hover {
    box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.2);
  }

  .playerDepthCharItem__header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 0.5rem;
  }

  .playerDepthCharItem__playerName {
    font-size: 1rem;
    font-weight: 700;
    color: #000;
  }
}
// SCSS for fanTeam news item Title, date, and description

.playerNewsItem {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #e0e0e0;
  border-radius: 0.5rem;
  background-color: #fff;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease-in-out;
 
  &:hover {
    box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.2);
  }

  .playerNewsItem__header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 0.5rem;
  }

  .playerNewsItem__playerName {
    font-size: 1rem;
    font-weight: 700;
    color: #000;
  }

  .playerNewsItem__date {
    font-size: 0.8rem;
    font-weight: lighter;
    color: #000;
  }

  .playerNewsItem__description {
    text-align: left;
    font-size: 1rem;
    font-weight: 400;
    color: #000;
    margin-bottom: 0.5rem;
  }
}
