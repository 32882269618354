.info-container {
  margin-left: 10%; /* Wide left margin */
  margin-right: 10%; /* Wide right margin */
  /* Adjust the above percentages as needed for your design */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
  height: 100vh;
  padding: 20px; /* Optional padding for inner spacing */
  margin-top: 1rem;
  margin-bottom: 2rem;
  background-color: #ffffff; /* Optional background color */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.intro {
    padding: 20px;
    background-color: #F5F5F5;
    margin-top: 5px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

.skills-section {
  padding: 20px;
  background-color: #f0f8ff;
  margin-top: 5px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
h1,
h2 {
  color: #0056b3;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  margin-bottom: 10px;
}
.skill-category {
  font-weight: bold;
  color: #007bff;
}

.draft-player-info-card__image {
  object-fit: cover;
  margin-right: 0.1rem; /* Add margin for spacing */
  border-radius: 33%;
  margin-bottom: 3rem;
}