.container {
  margin: 0 auto; /* Keep existing top, left and right margins */
  margin-bottom: 1rem; /* Add 1rem bottom margin */
  width: 100%;
  height: 800px;
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
  box-sizing: border-box; /* Include padding and border in the element's width and height */
}

.left, .middle, .right {
  // margin-top: .1rem;
  display: inline-block; 
  *display: inline; /* Hack for older IE browsers */
  // border-bottom: 1px solid #e4e7ea;
  vertical-align: top; 
  font-size: smaller;
  box-sizing: border-box; /* Include padding and border in the element's width and height */
  // margin-bottom: 1rem;
}

.left {
  width: 32%; 
  border-radius: .5rem;
}

.middle {
  width: 35%; 
  background: white;
  border: 1px solid gainsboro;
  border-radius: .5rem;
}

.right {
  width: 29%; 
  border-radius: .5rem;
}
