.draft-player-list-card {
  display: flex;
  flex-direction: column; /* Stack children vertically */
}
.draft-player-list-card__title {
  font-size: 16px;
  font-weight: 500;
  color: #333;
  margin-bottom: 8px;
  display: flex; /* Add flex container */
  align-items: center; /* Center items vertically */

  .draft-player-list-card__image {
    width: 50px; /* Adjust the width as needed */
    height: 50px; /* Adjust the height as needed */
    object-fit: cover;
    margin-right: 0.1rem; /* Add margin for spacing */
  }

  .draft-player-list-card__player-info {
    flex: 1; /* Take up remaining space */
  }
  .draft-player-list-card__name {
    font-size: larger;
    margin-bottom: 8px;
  }

  .draft-player-list-card__team {
    font-size: medium;
  }

  .draft-player-list-card__rankings {
    font-size: medium;
    display: flex; /* Use flexbox for layout */
    align-items: center; /* Center items vertically */
    justify-content: space-between; /* Space out the news icon and the player position */
  }

  .draft-player-list-card__rankings-table
    .draft-player-list-card__rankings-header {
    width: 3.5rem;
    text-align: center;
    font-size: small;
  }

  .draft-player-list-card__rankings-table {
    width: 100%;
  }

  .draft-player-list-card__news-icon {
    // filter: grayscale(60%) brightness(1.2); /* Example filter to change color to a shade of gray */
    fill: #a9a9a9; /* This only works if your image is inline SVG */
    /* Ensure the image comes first */
    width: 1rem; /* Adjust the width as needed */
    height: 1rem; /* Adjust the height as needed */
    order: -1; /* This will place the image before all other flex items */
    margin-right: 8px; /* Adjust the spacing to the right of the image */
    // padding-left: .1rem;
  }

  .draft-player-list-card__player-position {
    display: flex; /* Use flexbox for layout */
    align-items: center; /* Center items vertically */
    justify-content: space-between; /* Align children to the start of the container */
    text-align: left;
    font-size: large;
    font-weight: 800;
    padding-right: 1rem;
    padding-left: 1rem auto;
    /* No need for margin-left: auto; or flex-flow: row-reverse; since we want the image at the start */
  }

  .draft-player-list-card__rankings-value {
    text-align: center;
    font-size: smaller;
    color: #708090;
  }
}
