// Base card class
.draft-selection-card {
  background-color: #f0f3f5;
  box-shadow: 0 0.1rem 0.1rem 0 rgba(0, 0, 0, 0.1);
  padding: 1rem;
  margin-bottom: 0.1rem;
  display: flex;
  flex-direction: row;
  justify-content: safe flex-start;
  text-align: left;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  width: 100%;
  font-size: medium;
  border-radius: 1rem;

  // &:hover {
  //   box-shadow: 0 0.1rem 0.1rem rgba(0, 0, 0, 0.1);
  // }

  // &.selected {
  //   background-color: #f5f5f5;
  // }

  // Nested elements
  &__title {
    flex: 1;
    justify-content: space-between;
    margin-left: 0.5rem;
    font-size: 16px;
    color: #333;
    margin-bottom: 8px;
    font-weight: 500;
  }
}

// Modifier classes for different states
.draft-selection-card-my-team-pick {
  flex: 1;
  display: flex;
  padding: 1rem;
  background-color: #f0f8ff;
  border: 1px solid #1e90ff;
  border-radius: 1rem;
}

.draft-selection-card-on-the-clock {
  flex: 1;
  display: flex;
  padding: 1rem;
  background-color: #98fb98;
  border: 1px solid #ff0000;
  border-radius: 1rem;
}

// Additional specific classes for information elements
.draft-selection-pick-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 12%;
  // margin-left: 0.5rem;

  &__overall,
  &__overall_otc,
  &__overall_my_team_pick {
    font-size: large;
    font-weight: 600;
    text-align: center;
    color: white;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }

  &__overall {
    background-color: black;
    padding-left: 0.3rem;
    padding-right: 0.3rem;
  }

  &__overall_otc {
    background-color: #ff0000;
  }

  &__overall_my_team_pick {
    background-color: #1e90ff;
  }

  &__round {
    // padding: 0.1rem;
    padding-left: 0.3rem;
    padding-right: 0.3rem;
    font-size: medium;
    text-align: center;
    background-color: white;
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border: 1px solid #d3d3d3;
  }

  &__otc_item {
    flex: 6 0 auto;
    display: flex; // Ensure it's a flex container
    justify-content: space-between; // This will put space between the items
    align-items: center; // This will vertically center items in the container
    width: 100%; // Ensure it takes the full width
    box-sizing: border-box; // Include padding and border in the width calculation
    padding-right: 0.5rem; // Apply padding as needed
    margin-right: 1rem;
  }

  &__player_item {
    flex: 6 0 auto;
    display: flex; // Ensure it's a flex container
    justify-content: space-between; // This will put space between the items
    align-items: center; // This will vertically center items in the container
    width: 100%; // Ensure it takes the full width
    box-sizing: border-box; // Include padding and border in the width calculation
    padding-right: 0.5rem; // Apply padding as needed
    margin-right: 1rem;
  }

  &__player_position {
    font-size: larger;
  }
}

.clock-icon {
  color: #ff0000; /* This is the color for delete-trash */
}
