@import 'common.scss';

.position-grp-header {
  display: flex;
  justify-content: flex-start;
  align-items: center; /* Vertically center items */
  font-weight: 500;
  font-size: large;
}

.position-grp-count {
  padding-left: .7rem;
  align-content: baseline;
  font-weight: 500;
  font-size: large;
}
  
  .position-grp-detail {
    display: inline-block; 
    *display: inline; 
    zoom: 1; 
    vertical-align: top; 
    font-size: 12px;
    overflow: auto;
    overflow-x: hidden;
    // background-color: white;
    height: 50rem;
    width: 100%;
    border-style: ridge;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
}

.draft-position-card-item {
  font-size: larger;
}

/* Define a base class */
.base-background {
  /* Add common styles here */
  padding: 10px;
  border-radius: 5px;
  width: 3rem;
  border: 1px solid gray;
}

.background-color-1 {
  background-color: #ff0000; /* Red for QB */
  // background-color: qb-background;
}

.background-color-2 {
  background-color: #0000ff; /* Green for RB */ 
  // background-color: rb-background;
}

.background-color-3 {
  background-color: #F4A460, /* Blue for WR */
  // background-color: wr-background;
}

.background-color-4 {
  background-color: #2E8B57; /* Yellow for TE */
  // background-color: te-background;
}

.background-color-5 {
  background-color: #708090; /* Cyan for K */
  // background-color: k-background;
}

.background-color-6 {
  background-color: #6A5ACD; /* Magenta for DEF */
  // background-color: def-background;
}
