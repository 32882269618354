$base-qb-color: #ff0000;
$base-rb-color: #0000ff;
$base-wr-color: #FF8C00;
$base-te-color: #2E8B57;
$base-k-color: #708090;
$base-def-color: #9400D3; 

.qb-color {
  // color: #ff0000; /* Red for QB */
  color: $base-qb-color;
}

.rb-color {
  // color: #00ff00; /* Green for RB */
  color: $base-rb-color;
}

.wr-color {
  // color: #0000ff; /* Blue for WR */
  color: $base-wr-color;
}

.te-color {
  // color: #ffff00; /* Yellow for TE */
  color: $base-te-color;
}

.k-color {
  // color: #00ffff; /* Cyan for K */
  color: $base-k-color;
}

.def-color {
  // color: #fffafa; /* Magenta for DEF */
  color: $base-def-color;
}

$base-qb-background: #ff0000;
$base-rb-background: #0000ff;
$base-wr-background: #FF8C00;
$base-te-background: #2E8B57;
$base-k-background: #708090;
$base-def-background: #9400D3;

/* Define a base color class */
.qb-background {
  background-color: $base-qb-background;
  // background-color: $base-qb-background; /* Default text color */
}
.rb-background {
  background-color: $base-rb-background;
  // background-color: $base-rb-background; /* Default text color */
}
.wr-background {
  background-color: $base-wr-background;
  // background-color: $base-wr-background; /* Default text color */
}
.te-background {
  background-color: $base-te-background;
  // background-color: $base-te-background; /* Default text color */
}
.k-background {
  background-color: $base-k-background;
  // background-color: $base-k-background; /* Default text color */
}
.def-background {
  background-color: $base-def-background;
  // background-color: $base-def-background; /* Default text color */
}
/* Define a base background color class */
.base-background {
  color: #fff; /* Default background color */
}

.header-container {
  display: table;
  justify-content: space-between;
  align-items: center;
  padding: 0 .25rem;
  height: 9.6rem;
  width: 100%;
  // padding-top: 1rem;
  background-color: aliceblue;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  border: 1px inset #e4e7ea;
  // border-top-style: inset;
}

.detail-container {
  display: inline-block;
  *display: inline;
  zoom: 1;
  vertical-align: top;
  font-size: 12px;
  overflow: auto;
  overflow-x: hidden;
  background-color: white;
  height: 50rem;
  width: 100%;
  border-style: ridge;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.button-login {
  background-color: #4caf50;
  border: none;
  color: white;
  padding: 0.5rem 1rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1rem;
  margin: 0.5rem 0.5rem;
  cursor: pointer;
  border-radius: 0.5rem;

  &:disabled {
    background-color: #cccccc;
    color: #666666;
    cursor: not-allowed;
  }

  &:not(:disabled) {
    background-color: #4caf50;
    color: white;
    cursor: pointer;

    &:hover {
      background-color: #45a049;
    }
  }
}

.button-logout {
  background-color: #000000;
  border: none;
  color: white;
  padding: 0.5rem 1rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1rem;
  margin: 0.5rem 0.5rem;
  cursor: pointer;
  border-radius: 0.5rem;
  
  &:disabled {
    background-color: #cccccc;
    color: #666666;
    cursor: not-allowed;
  }

  &:not(:disabled) {
    background-color: #4caf50;
    color: white;
    cursor: pointer;

    &:hover {
      background-color: #45a049;
    }
  }
}

.button-submit {
  background-color: #4caf50;
  border: none;
  color: white;
  padding: 0.5rem 1rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1rem;
  margin: 0.5rem 0.5rem;
  cursor: pointer;
  border-radius: 0.5rem;

  &:disabled {
    background-color: #cccccc;
    color: #666666;
    cursor: not-allowed;
  }

  &:not(:disabled) {
    background-color: #4caf50;
    color: white;
    cursor: pointer;

    &:hover {
      background-color: #45a049;
    }
  }
}

.button-cancel {
  background-color: #f44336;
  border: none;
  color: white;
  padding: 0.5rem 1rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1rem;
  margin: 0.5rem 0.5rem;
  cursor: pointer;
  border-radius: 0.5rem;
}

/* Additional styles for consistency */
.base-border {
  border: 1px solid #ccc; /* Default border color */
}

.base-shadow {
  box-shadow: 2px 2px 5px #aaa; /* Default box shadow color */
}

/* Usage example */
// .my-element {
//   /* Apply the base color, background color, border, and shadow */
//   /* You can choose to use one or more of these classes based on your needs */
//   @extend .base-color;
//   @extend .base-background;
//   @extend .base-border;
//   @extend .base-shadow;
// }

.proTeamInfoPanel {
  display: flex;
  flex-direction: column; // This line ensures items are listed vertically
  align-items: flex-start; // Align items to the start of the container
  width: 100%;
  height: auto; // Adjust height to "auto" for dynamic sizing
  background-color: #f0f0f0;
  padding: 0.5rem; // Add some padding around the container
  overflow-y: auto; // Add vertical scroll if content overflows
  border-radius: .5rem;
  margin-bottom: 0.1rem;

  &--cardinals { background-color: #97233F; } // Replace with actual color
  &--falcons { background-color: #A71930; }
  &--ravens { background-color: #241773; }
  &--bills { background-color: #00338D; }
  &--panthers { background-color: #0085CA; }
  &--bears { background-color: #C83803; }
  &--bengals { background-color: #FB4F14; }
  &--browns { background-color: #311D00; }
  &--cowboys { background-color: #041E42; }
  &--broncos { background-color: #FB4F14; }
  &--lions { background-color: #0076B6; }
  &--packers { background-color: #203731; }
  &--texans { background-color: #03202F; }
  &--colts { background-color: #002C5F; }
  &--jaguars { background-color: #006778; }
  &--chiefs { background-color: #E31837; }
  &--raiders { background-color: #A5ACAF; }
  &--chargers { background-color: #002A5E; }
  &--rams { background-color: #003594; }
  &--dolphins { background-color: #008E97; }
  &--vikings { background-color: #4F2683; }
  &--patriots { background-color: #002244; }
  &--saints { background-color: #D3BC8D; }
  &--giants { background-color: #0B2265; }
  &--jets { background-color: #125740; }
  &--eagles { background-color: #004C54; }
  &--steelers { background-color: #FFB612; }
  &--49ers { background-color: #AA0000; }
  &--seahawks { background-color: #002244; }
  &--buccaneers { background-color: #D50A0A; }
  &--titans { background-color: #0C2340; }
  &--redskins { background-color: #FFB612; }
}

