.team_col {
  text-align: left;
  width: 100;
  color: white;
  padding-left: 5px;
  padding-top: 5px;
  padding-top: 5px;
  padding-bottom: 5;
  opacity: 0.75;
  // background-color: #f0f8ff;
}

.team_col:hover {
  background-color: #f0f8ff;
  // border: 1px solid green;
}

.player_col {
  padding-left: 5px;
  text-align: left;
  width: 75px;
  font-size: 12px;
  border: 1px solid #ddd;
  padding: 8px;
}

.drafted {
  background-color: #DCDCDC;
}

.drafted-MyTeam {
  background-color: #F0FFFF;
  border: 1px solid #4169E1;
}
