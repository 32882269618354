// SCSS for fanTeam news item Title, date, and description

.fanTeamNewsItem {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #e0e0e0;
    border-radius: 0.5rem;
    background-color: #fff;
    box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.1);
    transition: all 0.2s ease-in-out;
    &:hover {
      box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.2);
    }
  
    .fanTeamNewsItem__header {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 0.5rem;
    }
  
    .fanTeamNewsItem__playerName {
      font-size: 1rem;
      font-weight: 700;
      color: #000;
    }
  
    .fanTeamNewsItem__date {
      font-size: 0.8rem;
      font-weight: lighter;
      color: #000;
    }
  
    .fanTeamNewsItem__description {
      text-align: left;
      font-size: 1rem;
      font-weight: 400;
      color: #000;
      margin-bottom: 0.5rem;
    }
  }
  