@import 'common.scss';

.tab_Container_draftInfo {
  width: 100%;
  background-color: whitesmoke;
  padding-top: 0.1rem;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  // margin-bottom: 0.7rem;
}

.panel_draftInfo {
  display: inline-block;
  *display: inline;
  zoom: 1;
  vertical-align: top;
  font-size: 12px;
  overflow: auto;
  border-style: ridge;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

/* Tab Container */
.Tabs {
  width: 80%;
  height: auto;
  min-height: 400px;
  background: white;
  // padding: 2rem 1rem;
  color: #e8f0f2;
  border-radius: 1rem;
}

.react-tabs {
  -webkit-tap-highlight-color: transparent;
  // position: relative; /* Added if overlay is needed */
}

.react-tabs__tab-list {
  width: 90%;
  margin: 0 auto 0.5rem;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  border: 1px solid #dcdcdc;
  border-radius: 1rem;
  padding-left: 0pxz;
  margin-top: 1rem;
  font-size: medium;
  background-color: white;
  height: 2.5rem;
  text-align: center;
}

.react-tabs__tab {
  padding: 0.8rem;
  text-align: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
  border: 1px solid transparent; /* Example style */
  margin-right: 4px; /* Add some space between tabs */
  border-radius: 4px; /* Rounded corners for the tabs */
}

.react-tabs__tab--selected {
  font-weight: 700;
  height: 2.4rem;
  color: #ffff;
  border-radius: .5rem;
}

.react-tabs__tab--disabled {
  color: GrayText;
  cursor: default;
}

.react-tabs__tab:hover {
  background: #f0f8ff;
  border-radius: 1rem;
}

.react-tabs__tab:first-child {
  border-bottom-left-radius: 1rem;
  border-top-left-radius: 1rem;
}

.react-tabs__tab:last-child {
  border-bottom-right-radius: 1rem;
  border-top-right-radius: 1rem;
}

.react-tabs__tab-panel {
  display: none;
}

.react-tabs__tab-panel--selected {
  display: block;
  font-size: 1.25rem;
  text-align: center;
}

.react-tabs__tab--selected.qb-background {
  // background-color: #ff0000; /* Red for QB */
  background-color: qb-background;
}

.react-tabs__tab--selected.rb-background {
  // background-color: #0000ff; /* Green for RB */
  background-color: rb-background;
}

.react-tabs__tab--selected.wr-background {
  // background-color: #f4a460; /* Blue for WR */
  background-color: wr-background;
}

.react-tabs__tab--selected.te-background {
  // background-color: #2e8b57; /* Yellow for TE */
  background-color: te-background;
}

.react-tabs__tab--selected.k-background {
  // background-color: #708090; /* Cyan for K */
  background-color: k-background;
}

.react-tabs__tab--selected.def-background {
  // background-color: #6a5acd; /* Magenta for DEF */
  background-color: def-background;
}
