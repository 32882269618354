.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 320px;
  background-color: #fff;

  &__logo {
    display: grid;
    place-items: center;
    height: 120px;
    font-size: 1.5rem;
    font-weight: 700;
    font-family: "Mochiy Pop P one", sans-serif;
  }

  &__menu {
    position: relative;

    &__item {
      display: flex;
      align-items: center;
      place-content: flex-start;
      padding: 1rem 3rem;
      font-size: 1.25rem;
      font-weight: 500;
      color: #555555;
      transition: color 0.3s ease-in-out;

      &.active {
        color: #fff;
      }

      &__icon {
        margin-right: 1rem;

        i {
          font-size: 1.75rem;
        }
      }
    }

    &__indicator {
      position: absolute;
      top: 0;
      left: 50%;
      width: calc(100% - 40px);
      border-radius: 10px;
      background-color: #308efe;
      z-index: -1;
      transform: translateX(-50%);
      transition: 0.3s ease-in-out;
    }
  }
}

.link-button {
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 24;
  color: #008000;
  font-size: 2rem;
  border-radius: .5rem;
  border-color: white;
  border-style: none;
  // border-width: 0.1rem;
  background-color: white;
  
  &:hover {
    // box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
    border-radius: 5rem;
    opacity: 9;
    cursor: pointer;
    // border-style: inset;
    // background-color: #008000;
  }
}

//-- Google Material Icons
.material-symbols-outlined {
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 24;
  color: #008000;
  font-size: 2rem;
  border-radius: 5rem;
  &:hover {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    border-radius: 5rem;
  }
}

.delete-icon {
  color: #000000; /* This is the color for delete-trash */
  padding-left: .5rem;
}

.add-icon {
    color: #008000; /* This is the color for add_circle */
}

.dashboard-icon {
    color: #FFFFFF; /* This is the color for space_dashboard */
    padding-right: 1rem;
}

// .loading-icon {
//   color: #308efe; /* This is the color for add_circle */
// }
//--------------------------------------------------------------//

//-- Spinner
.loader_adding,
.loader_adding:before,
.loader_adding:after {
  background: #ffffff;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
  // padding-left: .5rem;
}
.loader_adding {
  color: #308efe;
  text-indent: -9999em;
  // margin: 88px auto;
  position: relative;
  font-size: 5px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader_adding:before,
.loader_adding:after {
  position: absolute;
  top: 0;
  content: '';
}
.loader_adding:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader_adding:after {
  left: 1.5em;
}
@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
