.proTeamInjuryItem {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #e0e0e0;
  border-radius: 0.5rem;
  background-color: #fff;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease-in-out;

  &:hover {
    box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.2);
  }

  .proTeamInjuryItem__header {
    display: flex;
    justify-content: space-between; // This spreads out the child elements evenly
    width: 100%;
  }

  .proTeamInjuryItem__player {
    font-size: 1rem;
    flex: 1; // This ensures that each game item takes equal space
    text-align: left; // Centering the text within each game item
    padding: 0.25rem; // Optional, for some internal spacing
  }
  
  .proTeamInjuryItem__injury {
    font-size: 0.95rem;
    flex: 1; // This ensures that each game item takes equal space
    text-align: left; // Centering the text within each game item
    padding: 0.25rem; // Optional, for some internal spacing
  }

  .proTeamInjuryItem__status {
    font-size: 0.9rem;
    flex: 1; // This ensures that each game item takes equal space
    text-align: right; // Centering the text within each game item
    padding: 0.25rem; // Optional, for some internal spacing
  }
  
}
