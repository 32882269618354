.draft-list-card {
  background-color: #f0f8ff;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  padding: 0.1rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  width: 95%;
  height: 5rem;
  margin-top: .75rem;
  margin-left: 1rem;
  margin-right: 2rem;
  border-radius: 1rem;
  padding-left: 0.2rem;
  font-size: large;
  &:hover {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    background-color: #f5f5f5;
  }
  &.selected {
    background-color: #f5f5f5;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  }
  &.draft-list-card-variant {
    background-color: #ffb6c1;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    padding: 0.1rem;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: left;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    width: 95%;
    height: 5rem;
    margin-left: 1rem;
    margin-right: 2rem;
    border-radius: 1rem;
    padding-left: 0.2rem;
    font-size: large;

    &:hover {
      background-color: #ffe4e1; // Adjust hover color for the gray variant
    }
  }
}
