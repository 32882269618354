.player-filter-content {
  display: flex;        /* Enable flexbox */
  flex-wrap: nowrap;    /* Prevent wrapping of items */
  justify-content: center; /* Center items horizontally */
  align-items: center;  /* Center items vertically (as you already had) */
  padding: .3rem;
  text-align: center;   /* Note: text-align may not be necessary with flexbox */
  background-color: #f2f2f2;
  border-radius: .5rem;
  margin: .2rem;
  border: 1px solid #e6e6e6;
}

.left-filter,
.middle-filter,
.right-filter {
  display: inline-block;
  *display: inline;
  zoom: 1;
  vertical-align: bottom;
  font-size: 12px;
  box-sizing: border-box;
}

.search-filter {
  margin-top: 1rem;
  height: 40px;
  width: 100%;
  padding-left: 5px;
  padding-bottom: .1rem;
}

input {
  border: 1px solid lightgrey;
  font-size: large;
  border-radius: 0.5rem;
  width: 100%;
  padding: 3px;
}

input:hover {
  border: 1px solid #a2d2ff;
  background-color: lightgrey;
}

input[type="text"]:focus {
  border-color: 3px solid #308efe !important;
}

.display-filter {
  border-radius: 5px;
  padding: .5rem;
  text-align: center;
  color: white;
  background-color: black;
  opacity: 0.7;
  font-size: larger;
}

.left-filter {
  width: 125;
  background: white;
  padding-right: 8px;
}

.middle-filter {
  width: 125;
  background: white;
}

.right-filter {
  width: 125;
  background: white;
  padding-left: 8px;
}

.drafted {
  background-color: #DCDCDC;
}
