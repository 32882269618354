.draftpick-card-content {
  padding: 30px;
  margin-left: 5px;
  margin-right: 5px;
  text-align: left;
  border-style: ridge;
  margin-bottom: 3px;
  background-color: whitesmoke;
  border-radius: 10px;
}
